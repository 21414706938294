<template>
  <div class="mod-dutylog">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          placeholder="按员工筛选"
          @visible-change="employeeSelect"
        >
          <el-option
            v-for="item in employeeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" style="width: 100%" border>
      <el-table-column
        label="序号"
        type="index"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column
        label="值班人员工"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column label="地点" prop="didian" align="center">
        <template slot-scope="scope">
          <div>
            {{ ["首都机场", "大兴机场"][scope.row.didian] }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="班次"
        prop="banci"
        align="center"
      ></el-table-column>
      <el-table-column
        label="人数"
        type="number"
        prop="renshu"
        align="center"
      ></el-table-column>
      <el-table-column
        label="事件详情"
        prop="event"
        align="center"
        min-width="200"
      ></el-table-column> -->
      <el-table-column
        label="创建时间"
        prop="createDate"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.createDate ? scope.row.createDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="170" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            plain
            @click="seeDetailHandler(scope.row.id)"
            >查看</el-button
          >
          <el-button
            type="danger"
            size="mini"
            plain
            @click="deleteHandler(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.page"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="dataForm.limit"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <see-detail
      v-if="seeDetail_visible"
      ref="SeeDetailDom"
      @refreshDataList="getDataList"
    />
  </div>
</template>
<script>
import SeeDetail from "./detylog-detail";

export default {
  components: {
    SeeDetail,
  },
  data() {
    return {
      dataList: [],
      seeDetail_visible: false,
      totalCount: 0,

      dataForm: {
        eid: "",
        page: 1,
        limit: 10,
      },
      employeeOptions: [], //员工列表选项
    };
  },
  created() {
    this.getDataList();
  },
  mounted() {},
  methods: {
    // 员工选择(打开下拉框)
    employeeSelect(e) {
      if (e) {
        this.$http.employee
          .list({
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.employeeOptions = res?.data?.list;
          });
      }
    },
    getDataList() {
      this.$http.dutylog.list(this.dataForm).then((res) => {
        if (res.data) {
          this.dataList = res.data.list || [];
          this.totalCount = res.data.totalCount;
        } else {
          this.dataList = [];
          this.totalCount = 0;
        }
      });
    },
    // 查看详情
    seeDetailHandler(id) {
      this.seeDetail_visible = true;

      this.$nextTick(() => {
        this.$refs["SeeDetailDom"].init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.dutylog.delete({ id, remarks: val }).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1000,
              onClose: () => {
                this.getDataList();
              },
            });
          });
        })
        .catch(() => {});
    },

    // 显示页数发生变化
    sizeChangeHandle(val) {
      this.dataForm.limit = val;
      this.dataForm.page = 1;
      this.getDataList();
    },
    // 当前页发生变化
    currentChangeHandle(val) {
      this.dataForm.page = val;
      this.getDataList();
    },
  },
};
</script>